import { lazy } from "react";
import Image from "next/image";
import { useRecoilValue } from "recoil";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import { ShipdaCurrentLanguage } from "@sellernote/_shared/src/i18n/i18nForShipda";
import { FORWARDING_AUTH_ATOMS } from "@sellernote/_shared/src/states/forwarding/auth";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import CommonStyled from "@sellernote/shipda-kr/src/components/landing/index.styles";
import LandingQuickActions from "@sellernote/shipda-kr/src/components/landing/LandingQuickActions";
import useCustomizeFloatingButtonAreaPosition from "@sellernote/shipda-kr/src/hooks/common/useCustomizeFloatingButtonAreaPosition";

import Layout from "../../containers/Layout";
import Seo from "../../containers/Seo";
import SuspenseWrapper from "../../containers/SuspenseWrapper";

import AEOSection from "./AEOSection";
import CustomerListSection from "./CustomerListSection";
import Registration from "./Registration";
import SectionIntro from "./SectionIntro";

const SubSectionList = lazy(() => import("./SubSectionList"));
const LandingSectionUseCases = lazy(
  () =>
    import(
      "@sellernote/shipda-kr/src/components/landing/LandingSectionUseCases"
    )
);
const LandingSectionHowWorks = lazy(
  () =>
    import(
      "@sellernote/shipda-kr/src/components/landing/LandingSectionHowWorks"
    )
);
const LandingSectionRequestServiceGuide = lazy(
  () =>
    import(
      "@sellernote/shipda-kr/src/components/landing/LandingSectionRequestServiceGuide"
    )
);

const customTags = (() => {
  const result = [];

  if (process.env.NEXT_PUBLIC_NAVER_SITE_VERIFICATION) {
    result.push({
      name: "naver-site-verification",
      content: process.env.NEXT_PUBLIC_NAVER_SITE_VERIFICATION,
    });
  }

  if (process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION) {
    result.push({
      name: "google-site-verification",
      content: process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION,
    });
  }

  return result;
})();

export default function Main() {
  const { isMobile } = useCheckIsMobile();

  const loggedIn = useRecoilValue(FORWARDING_AUTH_ATOMS.USER_LOGGED_IN);

  // Mobile에서는 최하단에 로그인여부에 따라 회원가입 버튼이 생기므로 플로팅 버튼 영역 위치를 조정
  useCustomizeFloatingButtonAreaPosition({
    position: loggedIn ? 50 : 82,
    disabled: !isMobile,
  });

  return (
    <Layout size="full" isTransparentHeader={true}>
      <Seo
        path="/"
        title={
          ShipdaCurrentLanguage.currentLanguage === "ko"
            ? "쉽다 - 디지털 수출입 물류 포워딩 서비스"
            : "Digital freight forwarding service - ShipDa"
        }
        description={
          ShipdaCurrentLanguage.currentLanguage === "ko"
            ? "디지털 포워딩 서비스 - 쉽다(ShipDa). 수출입운송부터 국내4PL 풀필먼트까지, 디지털 통합운송 솔루션"
            : "Digital Freight Forwarding Service, covering everything from global export/import transportation to Korea, to domestic 4PL fulfillment."
        }
        customTags={customTags}
      />

      <SuspenseWrapper fallback={<Loading active Image={Image} />}>
        <CommonStyled.container>
          <SectionIntro />

          <CustomerListSection />

          <AEOSection />

          <SubSectionList />

          {/* TODO: 아직 서비스 이전인 링크가 많아 이번 배포에서는 숨기기로 함. */}
          {/* <AdditionalServiceSection /> */}

          <LandingSectionUseCases backgroundColor={COLOR.wh} />

          <LandingSectionHowWorks
            sectionBackgroundColor={COLOR.bgColor_1}
            interviewBackgroundColor={COLOR.wh}
          />

          <LandingSectionRequestServiceGuide uiType="section" />

          <LandingQuickActions />

          <Registration />
        </CommonStyled.container>
      </SuspenseWrapper>
    </Layout>
  );
}
